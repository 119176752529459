import { makeStyles } from "@mui/styles";
import { style_exports } from "@exports/style_exports";
import contactusbg from "@images/contactusbg.png";
import contactsCardsBG from "@images/contactsCardsBG.png";

const input_radius = "0.5rem";
export const signup_styles = makeStyles({
  bordertop: {
    borderTop: `1px solid  #F2F2F2`,
  },
  buttonstylelogin: {
    backgroundColor: `${style_exports.grey_color}!important`,
    borderRadius: "2rem",
    fontSize: "20px",
    fontWeight: "600",
    color: "#333333",
    textTransform: "none",
  },
  inputstyle: {
    // background:`${style_exports.grey_color}!important`,
    "& .MuiOutlinedInput-root": {
      // background:`${style_exports.grey_color}!important`,
      borderRadius: `${input_radius}`,
      // height: `3.5rem`,
      "& fieldset": {
        border: "none", // Remove the border
      },
    },
    // borderRadius:`${input_radius}`,
    border: "none!important",
  },
  inputstyleCount: {
    marginBottom: "-18px",
    zIndex: 1,
    position: "relative",
    fontSize: "10px",
    fontWeight: 700,
    textAlign: "end",
    padding: "0rem 0.2rem"
  },
  borderText: {
    display: "inline-block",
    padding: "0 10px 10px 0px", // Adjust the distance from the text as needed
    textAlign: "center",
    position: "relative",
    borderRadius: "0.5rem", // Red border at the center

    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      width: "20px", // Adjust the width of the border as needed
      borderBottom: `5px solid ${style_exports.skyblue_color}`,
      borderRadius: "50px",
    },
  },
  borderText1: {
    display: "inline-block",
    padding: "0 10px 10px 0px", // Adjust the distance from the text as needed
    // textAlign: 'center',
    position: "relative",
    borderRadius: "0.5rem", // Red border at the center
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: "7%",
      transform: "translateX(-50%)",
      width: "20px", // Adjust the width of the border as needed
      borderBottom: `5px solid ${style_exports.skyblue_color}`,
      borderRadius: "50px",
    },
  },
});

// contact us styles
export const contactus_styles = makeStyles({
  imgrescontact: {
    width: "30px",
    height: "30px",
    objectFit: "contain",
    "@media (min-width: 300px) and (max-width: 599px)": {
      width: "30px",
    },
  },
  imgrescontact1: {
    width: "30px",
    height: "30px",
    objectFit: "contain",
    "@media (min-width: 300px) and (max-width: 599px)": {
      width: "20px",
    },
  },
  contactusbtn: {
    // width: "8.75rem",
    fontSize: "18px",
    fontWeight: 400,
    borderRadius: "2rem",
    "@media (min-width: 500px) and (max-width: 599px)": {
      fontSize: "16px",
      // width: "7.75rem",
    },
    "@media (min-width: 100px) and (max-width: 499px)": {
      fontSize: "12px",
      // width: "5rem",
    },
  },
  contactusgrid: {
    color: `${style_exports.black_shade_color}`,
    fontWeight: "600",
    fontSize: "1.5rem",
    fontFamily: "Open Sans",
  },
  contactmargin: {
    marginTop: "2rem",
    marginBottom: "2rem",
    "@media screen and (min-width: 100px) and (max-width: 599px)": {
      marginTop: "0rem",
      marginBottom: "1rem",
    },
  },
  contactus_text: {
    color: `${style_exports.skyblue_color}`,
    fontWeight: "bold",
    fontSize: "1.625rem",
    "@media screen and (max-width: 950px)": {
      fontSize: "1.2rem",
    },
  },
  textceenter: {
    textAlign: "center",
    marginLeft: "0.5rem",
    fontSize: "26px",
    fontWeight: "600",
    "@media screen and (max-width: 950px)": {
      fontSize: "1rem",
    },
  },
  textceenter1: {
    fontSize: "26px",
    "@media screen and (min-width: 100px) and (max-width: 950px)": {
      fontSize: "18px",
    },
  },
  // margintopbottom:{
  //   marginTop:   '7rem',
  //   marginBottom:'7rem',
  //   "@media screen and (min-width: 100px) and (max-width: 599px)":{
  //     marginTop:   '1rem',
  //     marginBottom:'1rem',
  //   },

  // },
  // margintopbottom1:{
  //   marginTop:   '7rem',
  //   "@media screen and (min-width: 100px) and (max-width: 599px)":{
  //     marginTop:   '0.5rem',
  //   },

  // },
  // margintopbottom2:{
  //   marginTop:   '2rem',
  //   "@media screen and (min-width: 100px) and (max-width: 599px)":{
  //     marginTop:   '0.5rem',
  //   },

  // },
  browsebtn: {
    background: `${style_exports.skyblue_color}`,
    borderRadius: "2rem",
    color: `${style_exports.btn_txtcolor}`,
    width: "138px",
    height: "42px",
    fontWeight: 700,
    fontSize: "16px",
  },
  contactusimage: {
    backgroundImage: `url(${contactusbg})`,
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    height: "420px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media screen and (min-width: 100px) and (max-width: 599px)": {
      height: "220px",
    },
  },
  contacttext: {
    color: `${style_exports.btn_txtcolor}`,
    fontSize: "1.5rem",
  },
  contactCardTitle: {
    color: `${style_exports.skyblue_color}`,
    fontSize: "1.5rem",
    fontWeight: "600",
  },
  contactCardInnerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
    gap: "1rem",
    height: "100%",
    width: "100%",
    backgroundImage: `url(${contactsCardsBG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "1rem",
    boxShadow: "#88ADC3 0px 20px 25px -5px,rgba(136, 173, 195, 0.5) 0px 10px 10px -5px",
    padding: "1rem 1rem 2rem 1rem",
  },
  contactCardInnerIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    boxShadow: "0px 4px 4px 0px #00000040",
    padding: "1rem",
    backgroundColor: "white",
  },
  contactCardInnerIcon: {
    width: "60%",
    height: "60%",
    objectFit: "contain",
  },
  contactCardInnerTitle: {
    color: `${style_exports.skyblue_color}`,
    fontSize: "1.5rem",
    fontWeight: "700",
    textAlign: "center",
  },
  contactCardInnerContent: {
    color: `${style_exports.shade_color}`,
    fontSize: "1rem",
    textAlign: "center",
    fontWeight: "400",
    width: "85%",
    "& a": {
      textDecoration: "none",
      color: `${style_exports.shade_color}`,
      "&:hover": {
        textDecoration: "underline",
      }
    },
  },
});
