import { Dialog } from "@mui/material";
import React from "react";
import mocaloaderAnimation from '../assets/loaders/loading_animation_3.json' //mocaLoader_animation.json'
// import yourmocaloader from '../assets/loaders/yourmocaloader.json'
import Lottie from "lottie-react";
const Loadingmodel = (props) => {
  const { open } = props;
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "transparent",
          boxShadow: "none",
          display:'flex',
          justifyContent:'center',
          width:{xs:"270px", xl:"350px"}, //"110px",
          height:{xs:"285px", xl:"365px"}, //"130px"
        },
      }}
    >
        <Lottie animationData={mocaloaderAnimation} />
        {/* mocaloader */}
        {/* <Lottie animationData={yourmocaloader} /> */}
      {/* <CircularProgress color="success" /> */}
      {/* <div style={{color:'#ffff'}}>Loadingmodel...</div> */}
    </Dialog>
  );
};

export default Loadingmodel;
