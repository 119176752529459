import { customEnqueueSnackbar } from "@constants/Jsxconstants";
import { useEffect, useState } from "react";
import { get_content_management } from "@services/Adminservices";
import { useLocation } from "react-router-dom";
import { cancellationRefundPolicy } from "../../components/constants/Userconstants";
function Privacytermshoc(WrappedComponent) {
  return function Privacytermshoc(props) {
    const [data, setData] = useState({});
    const location = useLocation();
    const getuserPrivacy_terms = async () => {
      try {

        const res = await get_content_management({
          type: (location?.pathname === "/privacypolicy" || location?.pathname === "/3ed68bd6-3704-4e4a-8f63-2ba45390ea0d") ? 1 : 2,
        });
        if (res?.status) {
          setData(res?.data);
        } else {
          customEnqueueSnackbar(res?.message, { variant: "error" });
        }
      } catch (err) {
        console.log(err);
      }
    };
    useEffect(() => {
      // console.log(location?.pathname === "/privacypolicy");
      if(location?.pathname === "/cancellation") {
        setData(cancellationRefundPolicy);
        return
      }
      getuserPrivacy_terms();
    }, []);
    return (
      <div>
        <WrappedComponent data={data} {...props} />
      </div>
    );
  };
}
export default Privacytermshoc;
