import { Dialog, DialogContent, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Customizedbutton from "@components/Customizedbutton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { style_exports } from "@exports/style_exports";
import VerifiedIcon from "@mui/icons-material/Verified";
import { LoginActions } from "@store/Loginslice";
import { customEnqueueSnackbar } from "@constants/Jsxconstants";
import { StorageClient } from "@helpers/Helperfunctions";
import { otp_resend, update_emailverification } from "../services/Userservices";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { detectIncognito } from "detectincognitojs";

const Emailverification = (props) => {
  /**
   * @param{type}-
   * 0->Give callback to mobileotp  in normal login and two factor authentication case
   * 1-> then move to create profile screen
   * 2->Restoring account after email verification again give callback to verify mobileotp
   * 3->Deactivation only email is not verified case then after verification calling the login api in signuplogin screen(Almost this case is not generated but for backup we have written this in web case)
   */
  const { open, close, type, handlemobileotp, loginres } = props;
  const user_data = useSelector((state) => state.authentication.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visitorId, setVisitorId] = useState("");
  /**Email verification */
  const Verifyemail_status = async () => {
    try {
      const mail_status = await update_emailverification({
        userId: loginres?.id//user_data?.id ?? loginres?.id,
      });

      if (mail_status?.status && mail_status?.data?.emailVerified) {
        // && mail_status?.data?.emailVerified
        switch (type) {
          case 0:
            /**callback to mobile otp case in register and two factor authentication */
            handlemobileotp(0);
            close();
            break;
          case 1:
            /**only email is not verified and after verification navigating to create profile */
            navigate("/mocalogin/accountcreated");
            StorageClient.setItem("login_status", 1);
            dispatch(LoginActions.userlogin([1, loginres]));//user_data
            break;
          case 2:
            /**Deactivation account activating the account by verification again and callback to mobileotp */
            handlemobileotp(4);
            close();
            break;
          case 3:
          /**Rare case in this flow we have firstly verifying email and then mobile */
        }

        close();
      } else {
        console.log(mail_status, "stat");
        customEnqueueSnackbar(
          "Please go through your mail and verify it by clicking the link.",
          { variant: "warning" }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  /**Handling resend otp  */
  const resendotp = async () => {
    console.log(loginres,"user data")
    try {
      let req = {
        userId: loginres?.id,
        emailOrMobile: loginres?.email,
        type: 1,
        resendType: 0,
        uniqueId: visitorId,
      };

      const resendotp = await otp_resend(req);
       
      if (resendotp?.status) {
        customEnqueueSnackbar("Verification link sent to your mail", {
          variant: "success",
        });
      } else {
        customEnqueueSnackbar(resendotp?.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const checkIncognitoMode = async () => {
      try {
        const fp = await FingerprintJS.load();
        // Generate the visitor ID
        const { visitorId: fingerprintVisitorId } = await fp.get();
        detectIncognito().then((result) => {
          if (result.isPrivate) {
            setVisitorId("INCOGNITO_MODE_" + fingerprintVisitorId);
          } else {
            setVisitorId(fingerprintVisitorId);
          }
        });
      } catch (error) {
        console.error("Error retrieving visitor identifier:", error);
      }
    };
    checkIncognitoMode();
  }, []);

  return (
    <>
      {/* onClose={close} */}
      <Dialog
        open={open}
        onClose={close}
        PaperProps={{
          sx: {
            position: "fixed",
            top: "30%",
            borderRadius: "2rem",
            // left: !props.right ? `${props.left}%` : '',
            // right: props.right ? `${props.right}%` : '',
            m: 0,
            minHeight: 200,
            maxHeight: 500,
            width: 500,
            maxWidth: 500,
            padding: "0rem",
            display: "flex",
            alignItems: "center",
            backdropFilter: "blur(15px)",
            "@media screen and (max-width: 599px)": {
              width: 290,
              maxWidth: 290,
            },
          },
        }}
      >
        {/* <DialogTitle> */}
        <Grid
          container
          direction="column"
          spacing={1}
          textAlign={"center"}
          mt={2}
        >
          <Grid item sx={{ fontWeight: "700" }}>
            Verify your Email
          </Grid>
          <Grid item>
            Please Check your email for a link to verify your email address.
          </Grid>
          <Grid item>Once verified, you'll be able to continue.</Grid>
        </Grid>
        {/* </DialogTitle> */}
        <DialogContent>
          <Grid container direction={"column"} spacing={1} textAlign={"center"}>
            <Grid item>
              <VerifiedIcon style={{ color: "green", fontSize: "5rem" }} />
            </Grid>
            <Grid
              item
              container
              justifyContent={"center"}
              alignItems={"center"}
            >
              {" "}
              <Customizedbutton
                data="Verify"
                onClick={() => Verifyemail_status()}
                swidth={"90px"}
                sheight={"38px"}
              />
            </Grid>
            <Grid item>
              Didn't receive an email?{" "}
              <span
                style={{
                  fontWeight: 700,
                  color: style_exports.skyblue_color,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => resendotp()}
              >
                Resend
              </span>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Emailverification;
